const sexshow = function(sex) {
    if (sex==1) {
        return "男"
    } else if (sex==0){
        return "女"
    }else{
        return '未知'
    }
}
const wuellipsis=function(value,n) {
    if (!value) return "";
    if (value.length >n) {
    return value.slice(0,n) + "...";
    }
    return value;
}
const priceFilter=function(s){
		  
    if (s) {
        var price=s+""
      return price.replace(/.00/gi,'')
    }
      return ""
  }
const addDay=function(s){
        
    if (!isNaN(s)) {
       
        return s+"天"
    }
    return s
}
export {
    sexshow,
    wuellipsis,
    priceFilter,
    addDay
}