import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 按需导入跑马灯...
// import {Carousel, CarouselItem,Rate,InputNumber,Timeline,TimelineItem,Card,Pagination,Dialog,Popover,MessageBox } from 'element-ui';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
ElementUI.Dialog.props.closeOnClickModal.default = false
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import myRequest from './http/index.js'
import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
Vue.prototype.$myRequest=myRequest
Vue.use(VideoPlayer)
Vue.use(VueQuillEditor)
Vue.use(ElementUI)
Vue.config.productionTip = false
import * as filters from '@/filters/index'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
document.title = "趣游网"
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
