import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 储存数据
  state: {
    user:"管理员",
    token:"",
    aboutlist:[]
  },
  // 修改数据
  mutations: {
    setUser(state,user){
      state.user=user
    },
    setToken(state,t){
      state.token=t
    },
    setaboutlist(state,obj){
      state.aboutlist=obj
    }

  },
  actions: {
  },
  modules: {
  }
})

