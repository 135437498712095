<template>
  <div id="app">

    <router-view/>

  </div>
</template>
<script>

export default {
	mounted(){
		if (this._isMobile()) {
			window.location.href="/mobile"
		} 
	},
	methods:{
		_isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag;
		}
	}
}
</script>
<style lang="scss">
$screenWidth:1200px;

html,body{
	width: 100%;
}
*{
	margin: 0;
	padding: 0;
	color: #333;
	box-sizing: border-box;
}
.hand{
	cursor:pointer !important;
}
.flex-around{
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.flex-between{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

li{
	list-style: none;
}
a{
	text-decoration: none;
}
input,textarea,select,button,a:focus {
     outline: none;
	 border: none;
	 box-shadow: 0px 0px 0px 0px;//去除阴影
 }
</style>
