import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';

Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: '/index' },
  // 头部导航
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main.vue'),
    meta: {
      title: '趣游网'
   },
    children: [
		  { path: '/', redirect: '/index' ,},
		  {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        meta: {
           title: '趣游网'
        }
		  }, {
			 path: '/teamCustomization',
			 name: 'teamCustomization',
			 component: () => import('../views/teamCustomization.vue'),
       meta: {
        title: '趣游网-城市微游'
      },
		 }, {
        path: '/cityTourism',
        name: 'cityTourism',
        component: () => import('../views/cityTourism.vue'),
        meta: {
          title: '趣游网-城市微游'
        }
		  }, {
        path: '/peripheralActivities',
        name: 'peripheralActivities',
        component: () => import('../views/peripheralActivities.vue'),
        meta: {
          title: '趣游网-周边活动'
        }
		  }, {
        path: '/domesticTravels',
        name: 'domesticTravels',
        component: () => import('../views/domesticTravels.vue'),
        meta: {
          title: '趣游网-国内长途'
        }
		  }, {
        path: '/travelAbroad',
        name: 'travelAbroad',
        component: () => import('../views/travelAbroad.vue'),
        meta: {
          title: '趣游网-境外旅行'
        }
		  },{
        path: '/quOutdoors',
        name: 'quOutdoors',
        component: () => import('../views/quOutdoors.vue'),
        meta: {
          title: '趣游网-趣·户外'
        }
      },{
        path: '/active',
        name: 'active',
        component: () => import('../views/active.vue'),
        meta: {
          title: '趣游网-趣·跟团'
        }
      },{
        path: '/quTravel',
        name: 'quTravel',
        component: () => import('../views/quTravel.vue'),
        meta: {
          title: '趣游网-趣·旅行'
        }
      }
    ]
  },
  // 用户页
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/user.vue'),
    beforeEnter: (to, from, next) => {
      if(localStorage.getItem("token")==null) {
        Message.warning('请先登录！');   
    
      } else{
        next()
      }
    },
    meta: {
      title: '趣游网'
   },
    children: [
		  { path: '/', redirect: '/user/my' },
		  {
        path: '/user/my',
        name: 'my',
        component: () => import('../views/user/my.vue')
		  }, {
        path: '/user/order',
        name: 'order',
        component: () => import('../views/user/order.vue')
		  }, {
        path: '/user/orderInfo',
        name: 'orderInfo',
        component: () => import('../views/user/orderInfo.vue')
		  }, {
        path: '/user/coupons',
        name: 'coupons',
        component: () => import('../views/user/coupons.vue')
		  }, {
        path: '/user/integral',
        name: 'integral',
        component: () => import('../views/user/integral.vue')
		  }, {
        path: '/user/mytravel',
        name: 'mytravel',
        component: () => import('../views/user/mytravel.vue')
		  }, {
        path: '/user/ordermsg',
        name: 'ordermsg',
        component: () => import('../views/user/ordermsg.vue')
		  }, {
        path: '/user/personinfo',
        name: 'personinfo',
        component: () => import('../views/user/personinfo.vue')
		  }, {
        path: '/user/collection',
        name: 'collection',
        component: () => import('../views/user/collection.vue')
		  }, {
        path: '/user/myquestion',
        name: 'myquestion',
        component: () => import('../views/user/myquestion.vue')
		  } ,{
        path: '/user/editpersoninfo',
        name: 'editpersoninfo',
        component: () => import('../views/user/editpersoninfo.vue')
		  },{
        path: '/user/editphone',
        name: 'editphone',
        component: () => import('../views/user/editphone.vue')
		  },{
        path: '/user/editpassword',
        name: 'editpassword',
        component: () => import('../views/user/editpassword.vue')
		  },{
        path: '/user/mycard',
        name: 'mycard',
        component: () => import('../views/user/mycard.vue')
		  },{
        path: '/user/buycard',
        name: 'buycard',
        component: () => import('../views/user/buycard.vue')
		  }, {
        path: '/user/useradress',
        name: 'useradress',
        component: () => import('../views/user/useradress.vue')
		  } ,{
        path: '/user/userticket',
        name: 'userticket',
        component: () => import('../views/user/userticket.vue')
		  }, {
        path: '/user/userlist',
        name: 'userlist',
        component: () => import('../views/user/userlist.vue')
		  } , {
        path: '/user/mycustom',
        name: 'mycustom',
        component: () => import('../views/user/mycustom.vue')
		  } ,{
        path: '/user/editemail',
        name: 'editemail',
        component: () => import('../views/user/editemail.vue')
		  } ,{
        path: '/user/djintegral',
        name: 'djintegral',
        component: () => import('../views/user/djintegral.vue')
		  } ,{
        path: '/user/mycomment',
        name: 'mycomment',
        component: () => import('../views/user/mycomment.vue')
		  } ,]
  },
    // 底部导航页
    {
      path: '/quyou',
      name: 'quyou',
      component: () => import('../views/footnav/qy.vue'),
      children: [
        { path: '/', redirect: '/quyou/about' },
        {
          path: '/quyou/about',
          name: 'about',
          component: () => import('../views/footnav/about.vue')
        },{
          path: '/quyou/advertisement',
          name: 'advertisement',
          component: () => import('../views/footnav/advertisement.vue')
        },{
          path: '/quyou/contact',
          name: 'contact',
          component: () => import('../views/footnav/contact.vue')
        },{
          path: '/quyou/exchangelinks',
          name: 'exchangelinks',
          component: () => import('../views/footnav/exchangelinks.vue')
        },{
          path: '/quyou/joinus',
          name: 'joinus',
          component: () => import('../views/footnav/joinus.vue')
        },{
          path: '/quyou/opinion',
          name: 'opinion',
          component: () => import('../views/footnav/opinion.vue')
        },{
          path: '/quyou/PR',
          name: 'PR',
          component: () => import('../views/footnav/PR.vue')
        },{
          path: '/quyou/privacy',
          name: 'privacy',
          component: () => import('../views/footnav/privacy.vue')
        },{
          path: '/quyou/privacyClause',
          name: 'privacyClause',
          component: () => import('../views/footnav/privacyClause.vue')
        },{
          path: '/quyou/useProtocol',
          name: 'useProtocol',
          component: () => import('../views/footnav/useProtocol.vue')
        }]
    },
  // 同级报名页面
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/signUp/signup.vue')
  }, {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/signUp/pay.vue')
  },
  // 同级旅拍页面
  {
    path: '/trivialShot',
    name: 'trivialShot',
    component: () => import('../views/trivelShot/index.vue'),
    meta: {
      title: '趣游网-旅记游拍'
    }
  }, {
    path: '/guidelist',
    name: 'guidelist',
    component: () => import('../views/trivelShot/guidelist.vue')
  }, {
    path: '/uptrival',
    name: 'uptrival',
    component: () => import('../views/trivelShot/uptrival.vue')
  }, {
    path: '/imginfo',
    name: 'imginfo',
    component: () => import('../views/trivelShot/imginfo.vue')
  }, {
    path: '/videoinfo',
    name: 'videoinfo',
    component: () => import('../views/trivelShot/videoinfo.vue')
  },{
    path: '/caseInfo',
    name: 'caseInfo',
    component: () => import('../views/caseInfo.vue')
  }, {
    path: '/searchlist',
    name: 'searchlist',
    component: () => import('../views/searchlist.vue')
  }, {
    path: '/supplement',
    name: 'supplement',
    component: () => import('../views/supplement.vue')
  }, {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue')
  },{
    path: '/quyou/rights',
    name: 'rights',
    component: () => import('../views/footnav/rights.vue')
  },{
    path: '/quyou/invitation',
    name: 'invitation',
    component: () => import('../views/footnav/invitation.vue')
  },,{
    path: '/updateInfo',
    name: 'updateInfo',
    component: () => import('../views/updateInfo.vue'),
    meta: {
      title: '趣游网-补充资料'
    }
  },{
    path: '/bannerinfo',
    name: 'bannerinfo',
    component: () => import('../views/bannerinfo.vue')
  },{
    path: '*',
    component: () => import('../views/404.vue')
  }

]

 

const router = new VueRouter({
  routes
  // mode: 'history'
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title = "趣游网"
  }
  
  if (from.path=="/" && (to.path.indexOf("user")>0 || to.name=="signup" )&& localStorage.getItem("token")==null) {
    console.log('from',from,to,to.path.indexOf("user"))
    next({ path: '/index' });
  }
  if (to.name=="signup" && localStorage.getItem("token")==null) {
    Message.warning('请先登录！');

  }else{
    window.scroll(0, 0);
    next()
  }
 



 
})
export default router
